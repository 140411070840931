import React from 'react';
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';

import './App.css';
import Home from './Pages/Home';
import About from './Pages/About';
import Tools from './Pages/Tools';
import Services from './Pages/Services';
import Gallery from './Pages/Gallery';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/tools" element={<Tools />} />
          <Route path="/services" element={<Services />} />
          <Route path="/gallery" element={<Gallery />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
